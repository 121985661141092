@media screen and (min-width: 1200px) {

    .wrapper{
        width: 100%;
        background-color: white;

    }
    .between{
        width: 100%;
        background-color: white;

    }

    .newfooter {
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        background-color: white;

        flex-direction: row;
        justify-content: space-between;
    }

  .newfooter__title {
    margin-block-start: 0em;
    margin-block-end: 0em;
    text-align: center;
    font-size: 30px;
    font-family: "Rubik";
    font-weight: 500;
    color: #7465fc;
  }

  .newfooter__text {
    line-height: 2rem;
    padding: 0;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 20px;
    font-family: "Rubik";
    color: #71717a;
    font-weight: 500;
    text-decoration: none;

  }

  .newfooter__text:hover{
    color: #7465fc;
  }

}

@media screen and (max-width: 1200px) {
    .wrapper{
        width: 100%;
        background-color: white;

    }
    .between{
        width: 100%;
        background-color: white;

    }

    .newfooter {
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        background-color: white;

        flex-direction: row;
        justify-content: space-between;
    }


  .newfooter__title {
    margin-block-start: 0em;
    margin-block-end: 0em;
    text-align: center;
    font-size: 20px;
    font-family: "Rubik";
    font-weight: 500;
    color: #7465fc;
  }

  .newfooter__text {
    line-height: 1rem;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 10px;
    text-decoration: none;
    font-family: "Rubik";
    color: #71717a;
    font-weight: 500;
  }
  .newfooter__text:hover{
    color: #7465fc;
  }
}
