

.banner {
  display: flex;
  background-color: #edf1fe;  /* edf1fe */
  justify-content: space-between;
  align-items: center;
  right: 0;
  height: 44px;
  max-width: 100%;
  padding: 0 20px;
  margin: auto;
  /* position: fixed;
  top: 0px; */

 
}



.banner p {
    font-size: 14px;
    flex: 1;
    margin: 0 1rem;
    color: #7465fc;
    font-family: 'Rubik';
    font-weight: 500;
    display: flex;
    justify-content: center;
}



