@media screen and (min-width: 1200px) {

  

.textblocktwo {
  padding-top: 6rem;
  padding-bottom: 6rem;

color: white;
}

.textblocktwo__title {
margin-block-start: 0em;
margin-block-end: 0em;
text-align: center;
font-size: 30px;
font-family: "Rubik";
font-weight: 500;
color: #7465fc;
}

.textblocktwo__text {
line-height: 2rem;
padding: 1rem 10rem 0rem 10rem;
text-align: center;
margin-block-start: 0em;
margin-block-end: 0em;
font-size: 20px;
font-family: "Rubik";
color: #71717a;
font-weight: 500;
}


}


@media screen and (max-width: 1200px) {

    

.textblocktwo {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left:2rem;
  padding-right:2rem;

color: white;
}

.textblocktwo__title {
margin-block-start: 0em;
margin-block-end: 0em;
text-align: center;
font-size: 20px;
font-family: "Rubik";
font-weight: 500;
color: #7465fc;
}

.textblocktwo__text {
line-height: 1rem;
padding: 1rem 0rem 0rem 0rem;
text-align: center;
margin-block-start: 0em;
margin-block-end: 0em;
font-size: 14px;
font-family: "Rubik";
color: #71717a;
font-weight: 500;
}

}