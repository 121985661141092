@media screen and (min-width: 1200px) {
  .google-div {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 15%;
    padding-right: 20px;
    background-size: contain;

  }


  .apple-div {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 15%;
    background-size: contain;

  }

  .downloadblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .downloadblock__text{
    margin-block-start: 0em;
margin-block-end: 0em;
padding-top: 6rem;
text-align: center;
font-size: 30px;
font-family: "Rubik";
font-weight: 500;
color: #7465fc;
  }

  .downloadblock__image {
    display: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  .row {
    height: 100px;
    width:100%;
    padding-top:2rem;
    padding-bottom:6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: #f5f5f7;
  }
}

@media screen and (max-width: 1200px) {
  .downloadblock {
    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .downloadblock__image {
    padding-top: 1rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: auto;
    width: 80%;
  }

  .row {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: #f5f5f7;
  }

  .google-div {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 40%;
    padding-right: 20px;
    background-size: contain;

  }


  .apple-div {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 40%;
    background-size: contain;

  }
  .downloadblock__text{
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-top: 3rem;
    text-align: center;
    font-size: 20px;
    font-family: "Rubik";
    font-weight: 500;
    color: #7465fc;
  }
}
