@media screen and (min-width: 1200px) {
    .wrapper {
      background-color: #f5f5f7;
    }
  
    .textblock {
      background-color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  
    .textblock__title {
      text-align: left;
      font-size: 30px;
      font-family: "Rubik";
      font-weight: 500;
      color: #7465fc;
  
    }
  
    .textblock__text {
      line-height: 2rem;
      text-align: left;
      font-size: 20px;
      font-family: "Rubik";
      color: #71717a;
      font-weight: 500;
      margin-block-start: 0rem;
      width: 100%;
    }
  
    .spacer {
      color: white;
    }
  
    .illustration__phone {
      padding-top: 5.5rem;
      padding-left: 10rem;
      width: 40%;
    }
  
    .row-illustration {
      flex-direction: row;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      max-width: 80%;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .wrapper {
      background-color: #f5f5f7;
    }
  
    .textblock {
      background-color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  
    .textblock__title {
      text-align: left;
      font-size: 20px;
      font-family: "Rubik";
      font-weight: 500;
      color: #7465fc;
    }
  
    .textblock__text {
      line-height: 1rem;
      text-align: left;
      font-size: 14px;
      font-family: "Rubik";
      color: #71717a;
      font-weight: 500;
      margin-block-start: 0rem;
    }
  
    .spacer {
      color: white;
    }
  
    .illustration__phone {
      display: none;
    }
  
    .row-illustration {
      flex-direction: row;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      max-width: 80%;
    }
  }
  