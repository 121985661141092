
@media screen and (min-width: 1200px) {

.bar-menu{
    display: flex;
    /* margin-top: 44px; */
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
    background-color: white;
    max-width: 80%;
    height: 88px;
    padding: 0 20px;
}

.bar-menu__title{
    font-size: 32px;
    color: #7465fc;
    font-family: 'Rubik';
    font-weight: 600;
    cursor:pointer;
}
.bar-menu__title:hover{
    color: #7465fc;
}

.bar-menu__options{
    display: flex;
    height: 2.5rem;
    align-items: center;
}

.bar-menu__home{
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    cursor:pointer;
}

.bar-menu__home:hover{
    color: #7465fc;
}

.bar-menu__support{
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    cursor:pointer;
}

.bar-menu__support:hover{
    color: #7465fc;
}

.bar-menu__download{
    align-items: center;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    color: #7465fc;
    height: 2.5rem;
    background: #edf1fe;
    border-radius: 9999px;
    cursor:pointer;
}

.bar-menu__download:hover{
    background-color: #e3eafc;
}

.bar-menu__scarica{
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 500;
    cursor:pointer;
}

.bar-menu__scarica:hover{
    color: #7465fc;
}

}


@media screen and (max-width: 1200px) {

    .bar-menu{
        display: flex;
        /* margin-top: 44px; */
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem;
        background-color: white;
        max-width: 80%;
        height: 88px;
        padding: 0 20px;
    }
    
    .bar-menu__title{
        font-size: 20px;
        color: #7465fc;
        font-family: 'Rubik';
        font-weight: 600;
        cursor:pointer;
    }
    .bar-menu__title:hover{
        color: #7465fc;
    }
    
    .bar-menu__options{
        display: flex;
        height: 2.5rem;
        align-items: center;
    }
    
    .bar-menu__home{
        padding-left: 1rem;
        padding-right: 1rem;
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: 500;
        cursor:pointer;
    }
    
    .bar-menu__home:hover{
        color: #7465fc;
    }
    
    .bar-menu__support{
        padding-left: 1rem;
        padding-right: 1rem;
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: 500;
        cursor:pointer;
    }
    
    .bar-menu__support:hover{
        color: #7465fc;
    }
    
    .bar-menu__download{
        display: none;
    }

    .bar-menu__scarica{
        display: none;
    }

    


    
    .bar-menu__download:hover{
        background-color: #e3eafc;
    }
    
    }