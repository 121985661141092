.download__playstore {
  max-width: 100%;
  max-height: 100%;
}

.download__applestore {
  max-width: 100%;
  max-height: 100%;
}

.download__row-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  text-align: center;
}

@media screen and (min-width: 1201px) {
  .download__google-div {
    border-radius: 20px 20px 20px 20px;
    margin-right: 10px;
    height: 60px;
    cursor: pointer;
    overflow: hidden;
  }
  .download__apple-div {
    border-radius: 20px 20px 20px 20px;
    height: 60px;
    cursor: pointer;
    overflow: hidden;
  }

  .download__wrapper {
    background-color: #f5f5f7;
    width: 100%;
    margin-right: 0;
  }

  .download__body {
    max-height: 60%;
    max-width: 80%;
    margin: auto;
    border-radius: 40px;
    background-color: white;
  }

  .download__row {
    max-width: 100%;
    padding-top: 0rem;
    padding-bottom: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .download__body-column-left {
    display: flex;
    flex-direction: column;
    padding: 5rem;
    height: 100%;
    width: 35%;
  }

  .download__body-column-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 40%;
    padding-right: 5rem;
    padding-bottom: 2rem;
    background-size: contain;

  }

  .download__body-column__word {
    font-weight: 700;
    font-size: 60px;
    color: black;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 1.5rem;
  }

  .download__body-column__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .download__body-column__row-line {
    height: 3px;
    width: 50px;
    background-color: #a7b6c5;
    margin-right: 1rem;
  }

  .download__body-column__row-line-text {
    display: flex;
    font-weight: 500;
    font-size: 25px;
    color: #7465fc;
    align-content: center;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: left;
    font-family: "Rubik";
  }

  .download__body-column__description {
    font-weight: 500;
    font-size: 20px;
    color: #adafb2;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 2rem;
    font-family: "Rubik";
  }

  .download__button {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: 150px;
    padding: 20px;
    color: #7465fc;
    border-radius: 20px;
    background: #edf1fe;
    font-family: "Rubik";
    font-weight: 500;
    cursor: pointer;
  }

  .download__button:hover {
    background-color: #e3eafc;
  }

  .download__space {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 1200px) {
  .download__google-div {
    margin-right: 10px;
    max-width: 100px;
    cursor: pointer;
    overflow: hidden;
    padding: auto;
    display: block;
  }
  .download__apple-div {
    max-width: 100px;
    cursor: pointer;
    overflow: hidden;
    padding: auto;
    margin: auto;
    display: block;
  }

  .download__wrapper {
    background-color: #f5f5f7;
    width: 100%;
    margin: 0;
  }

  .download__body {
    max-height: 60%;
    max-width: 80%;
    margin: auto;
    border-radius: 40px;
    background-color: white;
  }

  .download__row {
    display: flex;
    justify-content: center;
  }

  .download__body-column-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0rem;
    padding: 3rem;
  }

  .download__body-column-right {
    display: none;
  }

  .download__body-column__word {
    font-weight: 700;
    font-size: 2rem;
    color: black;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 1.5rem;
  }

  .download__body-column__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .download__body-column__row-line {
    height: 3px;
    width: 2.5rem;
    background-color: #a7b6c5;
    margin-right: 1rem;
  }

  .download__body-column__row-line-text {
    display: flex;
    font-weight: 500;
    font-size: 1.5rem;
    color: #7465fc;
    align-content: center;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: left;
    font-family: "Rubik";
  }

  .download__body-column__description {
    font-weight: 500;
    font-size: 1rem;
    color: #adafb2;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 2rem;
    font-family: "Rubik";
  }

  .download__button {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: 150px;
    padding: 20px;
    color: #7465fc;
    border-radius: 20px;
    background: #edf1fe;
    font-family: "Rubik";
    font-weight: 500;
    cursor: pointer;
  }

  .download__button:hover {
    background-color: #e3eafc;
  }

  .download__space {
    padding-top: 0rem;
  }
}
